/* CSS Variables for Consistency */
:root {
  --primary-color: #00f7ff;
  --accent-color: #c4ff02;
  --bg-dark: #121212;
  --card-bg: #1a1a1a;
  --gold: #ffd700;
  --silver: #c0c0c0;
  --bronze: #cd7f32;
  --gray-badge-bg: #ccc;
  --gray-badge-text: #333;
  --border-color: #00c3ff;
  --font-stack: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/* Overall Page Styles */
.leaderboard-page {
  background-color: var(--bg-dark);
  color: #f0f0f0;
  min-height: 100vh;
  padding-top: 50px;
  font-family: var(--font-stack);
  text-align: center;
}

.leaderboard-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.leaderboard-title {
  font-size: 36px;
  color: var(--primary-color);
  text-shadow: 0 0 8px var(--primary-color);
  margin-bottom: 30px;
}

/* --- Top Three Section (Triangle Layout) --- */
.top-three-container {
  position: relative;
  height: 140px; /* Adjust height as needed */
  margin-bottom: 40px;
}

.top-item {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
}
.top-item:hover {
  transform: translateY(-3px);
}

/* Rank 1: Centered at the top */
.top-item.rank1 {
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

/* Ranks 2 and 3: Positioned beside Rank 1 with vertical offset (approx 40px) */
.top-item.rank2 {
  left: 20%;
  top: 40px;
}
.top-item.rank3 {
  right: 20%;
  top: 40px;
}

/* Avatar Container */
.avatar-container {
  position: relative;
  display: inline-block;
}

/* Avatar for Top Items */
.avatar {
  width: 80px;
  height: 80px;
  background-color: #1f1f1f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: bold;
  border: 2px solid transparent;
}

/* Rank Badge for Top 3 (positioned at the top of the avatar) */
.rank-badge.top-badge {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  border: 1px solid #fff;
}

/* Colors for Top 3 Badges */
.rank1-badge {
  background-color: var(--gold);
}
.rank2-badge {
  background-color: var(--silver);
}
.rank3-badge {
  background-color: var(--bronze);
}

/* Top Item Name & Donation */
.top-item .name {
  font-size: 16px;
  font-weight: 500;
  margin-top: 4px;
}
.top-item .donation {
  font-size: 14px;
  color: var(--accent-color);
  text-shadow: 0 0 4px var(--accent-color);
}

/* --- Extended Podium (Ranks 4–7) --- */
.extended-podium {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px; /* 5-pixel gap between items */
  margin-bottom: 30px;
}

.extended-item {
  text-align: center;
}

.extended-item .avatar {
  width: 50px;
  height: 50px;
  background-color: #1f1f1f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid var(--border-color);
  position: relative;
}

/* For extended items, position the badge at the BOTTOM of the avatar */
.rank-badge.extended-badge {
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  line-height: 20px;
  background-color: var(--gray-badge-bg);
  color: var(--gray-badge-text);
  border: 1px solid var(--gray-badge-bg);
}

.extended-item .name {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 4px;
}
.extended-item .donation {
  font-size: 14px;
  color: var(--accent-color);
}

/* --- Others Section (Ranks 9+) --- */
.others-section {
  margin-top: 30px;
  text-align: left;
}
.holders-title {
  font-size: 24px;
  color: var(--primary-color);
  margin-bottom: 10px;
  text-align: center;
}
.holders-list {
  max-height: 300px;
  overflow-y: auto;
  background-color: var(--card-bg);
  border: 2px solid var(--border-color);
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 0 8px var(--border-color);
}
.other-item {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  border-bottom: 1px solid var(--border-color);
}
.other-item:last-child {
  border-bottom: none;
}
.other-item .rank-number {
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
  width: 24px;
  text-align: center;
}
.other-item .avatar {
  width: 35px;
  height: 35px;
  background-color: #1f1f1f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  border: 2px solid var(--border-color);
  margin-right: 5px; /* Reduced gap between rank number and avatar */
}
.other-item .name {
  flex: 1;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.other-item .donation {
  font-size: 14px;
  color: var(--accent-color);
  margin-left: 10px;
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .row2 {
    gap: 20px;
  }
  .extended-podium {
    grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
    gap: 5px;
  }
}

