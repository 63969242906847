/* Reset global defaults */
html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* important for preventing horizontal scroll bars */
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

/* Container uses min-height instead of fixed 100vh */
.calendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* or center */
  min-height: 100vh;
  width: 100%;
  padding: 75px 20px 20px 20px;
  background: linear-gradient(135deg, #1e293b, #0f172a);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  animation: bg-gradient 5s infinite alternate;
}

@keyframes bg-gradient {
  0% {
    background: linear-gradient(135deg, #1e293b, #0f172a);
  }
  100% {
    background: linear-gradient(135deg, #0f172a, #1e293b);
  }
}

/* Remove absolute positioning from the header */
.calendar-header {
  color: #00ffff;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px; 
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

/* Timer */
.timer {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #08f4ad;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0%, 100% { opacity: 1; }
  50%      { opacity: 0.6; }
}

/* Grid */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

/* Day box styles */
.calendar-day {
  background-color: #1f2937;
  border: 2px solid #00ffff;
  border-radius: 12px;
  text-align: center;
  padding: 15px;
  transition: 
    transform 0.3s ease,
    box-shadow 0.3s ease,
    background-color 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.calendar-day:hover {
  transform: scale(1.05);
  background-color: #2d3748;
  box-shadow: 0 4px 10px rgba(0, 255, 255, 0.5);
}

.calendar-day.active {
  background-color: #00ffff;
  color: #dbdbdb;
  transform: scale(1.1);
  box-shadow: 0 4px 15px rgba(0, 255, 255, 0.75);
  animation: glow 2s infinite alternate;
}

@keyframes glow {
  0%   { box-shadow: 0 4px 10px rgba(0, 255, 255, 0.5); }
  100% { box-shadow: 0 6px 20px rgba(0, 255, 255, 0.9); }
}

.calendar-day.collected {
  opacity: 0.5;
  pointer-events: none;
  background-color: #2d3748;
  color: #4b5563;
}

.day-number {
  font-size: 1.2rem;
  font-weight: bold;
  color: #d0ef05;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.calendar-day.active .day-number {
  color: #1a202c;
}

.reward-info {
  margin-top: 10px;
  font-size: 1rem;
  color: #04ee5e;
  animation: fadeInUp 0.7s ease;
}

@keyframes fadeInUp {
  from { transform: translateY(10px); opacity: 0; }
  to   { transform: translateY(0);     opacity: 1; }
}

.reward-name {
  font-size: 1rem;
  font-weight: bold;
}

.claim-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: rgb(208, 244, 1);
  border: none;
  border-radius: 8px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.claim-button:hover {
  background-color: #45a049;
  transform: scale(1.1);
}

/* Premium day */
.calendar-day:nth-child(7) {
  grid-column: span 3;
  background: linear-gradient(135deg, #ff9f43, #ff6f61);
  color: white;
  transform: scale(1.05);
  border: 2px solid #fff;
  font-size: 1.2rem;
  box-shadow: 0 4px 10px rgba(255, 111, 97, 0.5);
  position: relative;
  animation: premiumDayPulse 3s infinite ease-in-out;
}

@keyframes premiumDayPulse {
  0% {
    transform: scale(1.05) translateY(0);
    box-shadow: 0 4px 10px rgba(255, 111, 97, 0.5);
  }
  50% {
    transform: scale(1.1) translateY(-5px);
    box-shadow: 0 6px 15px rgba(255, 111, 97, 0.7);
  }
  100% {
    transform: scale(1.05) translateY(0);
    box-shadow: 0 4px 10px rgba(255, 111, 97, 0.5);
  }
}

.calendar-day:nth-child(7):hover {
  transform: scale(1.2) translateY(-10px);
  background: linear-gradient(135deg, #ff6f61, #ff9f43);
  box-shadow: 0 8px 20px rgba(255, 111, 97, 0.8);
}
