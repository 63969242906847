/* EatsApp.css */
* {
  margin: 0;
  padding: 0;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html, body {
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

/* The root container for the app */
#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App {
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  background-color: rgb(0, 0, 0);
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  z-index: 1;
  
}

.logo {
  position: fixed;
  top: 11%;
  left: 4%;
  width: 50px; 
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  background: transparent;
  border: none;
  box-shadow: none;
}


.wallet {
  color: #04ecd951;
  font-size: 8px;
  font-weight: 800;
  width: 10%;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.birge {
  color: #04ecd951;
  font-size: 8px;
  font-weight: 800;
  width: 10%;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.wallet-birge {
  gap: 5px;
  flex-direction: column;
  margin-top: 6%;
  padding: 0 4%;
  display: flex;
  justify-content: start;
  width: 100%;
  height: 4%;
  position: relative;
  border-radius: 4px;
}

.wallet-img {
  width: 0%;
  height: 0%;
  object-fit: cover;
}

.birge-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.balance-container {
  border: 2px solid #37add4;
  border-radius: 12px;
  background: linear-gradient(45deg, #0f0f0f, #2a2a2a, #4d4d4d, #0f0f0f);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5), 0 0 20px #00c8ff;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  font-weight: 600;
  color: #00e1ff;
  text-shadow: 0 0 8px #ffd700;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  min-width: 100px;
  text-align: center;
}

.balance-icon {
  width: 34px;
  height: 34px;
}

.clicker-container {
  perspective: 1000px;
  margin-top: 60%;
  border-radius: 50%;
  outline: none;  
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 48%;
}

.clicker-image {
  position: relative;
  transform-style: preserve-3d;
  width: 80%;
  height: 80%;
  object-fit: cover;
  pointer-events: none;
  transition: transform 0.1s ease-out;
  will-change: transform;
  transform-origin: center center;
  transition: transform 0.15s ease-out; 
  transform-origin: center; 
}

.clicker-container {
  perspective: 1000px;
}

.clicker-image {
  transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  transform-origin: center;
}

.clicker-image.distorted {
  transition: transform 0.1s ease-out;
}

.ripple {
  position: absolute;
  width: 10px;
  height: 10px;
  background: rgba(0, 150, 255, 0.5);
  border-radius: 50%;
  pointer-events: none;
  animation: rippleEffect 0.9s ease-out forwards;
  will-change: transform, opacity;
}

.tap-message {
  border-radius: 10% 0% 30% 10%;
  padding: 5px;
  border: 5px solid rgb(0, 246, 226);
  position: absolute;
  font-weight: 800;
  font-size: 18px;
  color: #00f6e2;
  text-align: center;
  z-index: -1;
  animation: pulse 0.5s ease-in-out infinite;
  box-shadow: 
    0 0 4px #00f6e2, 
    0 0 8px rgba(0, 246, 226, 0.6), 
    0 0 12px rgba(0, 246, 226, 0.4);
  top: calc(25% + 10px);
  left: 5%;
}

@keyframes pulse {
  0% { transform: scale(1); }
  25% { transform: scale(1.1); }
  50% { transform: scale(1); }
  75% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes rippleEffect {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.6;
  }
  100% {
    transform: translate(-50%, -50%) scale(12);
    opacity: 0;
  }
}

.tabs button .icon {
  width: 34px ;
  height: 34px;
}

.tabs {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: 5px 5px 0px 0px;
  background-color: #000000e7;
  border-top: 5px solid rgb(0, 195, 255);
  height: max-content;
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
  box-shadow: 0 0 5px rgb(0, 195, 255), 0 0 10px rgb(0, 195, 255), 0 0 15px rgb(0, 195, 255);
  animation: neonGlow 1.5s infinite alternate;
}

.up-cont {
  background-color: #010000e7;
  width: 100%;
  height: max-content;
  position: relative;
}

.Energy-descr {
  font-weight: 500;
  font-size: 18px;
  font-family: monospace;
  top: 65px;
  right: 45%;
  position: absolute;
  color: #07ffea;
  text-shadow: 
    0 0 10px rgba(7, 255, 234, 0.8), 
    0 0 20px rgba(7, 255, 234, 0.6);
}

@keyframes neonGlow {
  0% {
    box-shadow: 0 0 5px rgb(0, 174, 255), 0 0 10px rgb(0, 174, 255), 0 0 15px rgb(0, 174, 255);
  }
  100% {
    box-shadow: 0 0 10px rgb(0, 174, 255), 0 0 20px rgb(0, 174, 255), 0 0 30px rgb(0, 174, 255);
  }
}

.bs1 button {
  border-radius: 50%;
  overflow: hidden;  
  background-color: transparent;
  border: none;
}

.upgrades button {
  text-align: start;
  margin-top: 1%;
  width: 70%;
  background-color: rgba(188, 1, 1, 0.6);
  color: rgb(0, 0, 0);
  padding: 1%;
  cursor: pointer;
  border-radius: 5px;
}

.total-e {
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Android override: If the device is Android, adjust top offset to 60px */
.total-e.android-total {
  top: 35px;
}

.energy-icon {
  top: 65px;
  right: 51%;
  transform: translateX(-50%);
  position: absolute;
  width: 24px;
}

.energy-container {
  justify-content: center;
  margin-right: 3%;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: #0000009b;
  text-shadow: 
    0 0 5px #00ffff,
    0 0 15px #00ffff, 
    0 0 20px #ccff00,
    0 0 30px #ff5100;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.disabled .icon {
  filter: grayscale(100%) contrast(100%);
}

.tab-content {
  height: 100vh;
  margin: 0;    
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); 
  z-index: 10; 
  transform: translateY(100%);
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  opacity: 0;
}

.tab-content.open {
  transform: translateY(0);
  opacity: 1;
}

.tabs button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  background-color: #fef7f700;
  width: 20%;
  border: none;
  position: relative; /* for the radial effect */
}

.tabs button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: radial-gradient(circle, rgba(255,255,255,0.5) 20%, rgba(245, 242, 242, 0) 60%);
  transition: transform 0.5s ease;
  transform: translate(-50%, -50%) scale(0);
  z-index: -1;
}

@keyframes glow {
  0% { 
    color: rgba(196, 255, 2, 1);
    text-shadow: 
      0 0 5px #00ffff,
      0 0 10px #00ffff, 
      0 0 15px #00ffff, 
      0 0 20px #ff00ff,
      0 0 25px #ff00ff, 
      0 0 30px #ff00ff;
    box-shadow: 
      0 0 10px #00ffff,
      0 0 20px #0084ff, 
      0 0 30px #ff00ff,
      0 0 10px #ff00ff;
  }
  100% { 
    color: #fff;
    text-shadow: 
      0 0 10px #00ffff, 
      0 0 20px #00ffff, 
      0 0 30px #00ffff, 
      0 0 10px #ff00ff, 
      0 0 10px #ff00ff, 
      0 0 10px #ff00ff;
    box-shadow: 
      0 0 15px #00ffff, 
      0 0 15px #00ffff, 
      0 0 15px #ff00ff, 
      0 0 2px #ff00ff;
  }
}

.back-button {
  font-family: monospace;
  z-index: 4;
  position: absolute;
  top: 10px; 
  left: 10px; 
  font-weight: bold;
  background-color: rgba(0, 247, 255, 0.863);
  color: rgba(0, 0, 0, 0.787);
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 
    0 0 5px rgba(0, 247, 255, 0.7);
    
  transition: transform 0.2s, box-shadow 0.3s;
}

.damage-indicator {
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  text-shadow: 
    0 0 5px #00ffff,
    0 0 10px #00ffff, 
    0 0 15px #00ffff, 
    0 0 20px #ff00ff,
    0 0 25px #ff00ff, 
    0 0 30px #ff00ff;
  animation: fadeOut 1s forwards, scatter 0.5s forwards;
}

@keyframes fadeOut {
  0% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-50px); }
}

.balance-text {
  color: white;
}

@keyframes neon {
  0%, 100% {
    box-shadow: 0 0 3px #00fbff, 0 0 8px #aa00ff, 0 0 15px #c300ff, 0 0 17px #00fbff, 0 0 20px #d000ff, 0 0 25px #00fbff;
  }
  50% {
    box-shadow: 0 0 7px #00fee9, 0 0 15px #00fee9, 0 0 20px #00fee9, 0 0 25px #00fee9, 0 0 30px #00fee9, 0 0 35px #00fee9;
  }
}

/* Referral System Styles */
.tab-container {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: #1212125d;
}

.back-button {
  position: absolute;
  top: 100px;
  left: 20px;
  z-index: 101;
  background: #00f7ff;
  border: none;
  padding: 8px 16px;
  font-weight: bold;
  border-radius: 4px;
}

.referral-page-container {
  height: 100vh;
  padding: 20px;
  color: white;
  background: #121212;
}

.referral-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 40px;
}

.friend-rewards-title {
  text-align: center;
  font-size: 24px;
  color: white;
  margin: 20px 0;
}

.referral-link-block {
  background: rgba(128, 128, 128, 0.8);
  padding: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copy-button {
  color: #00f7ff;
  background: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
}

.reward-block {
  background: rgba(128, 128, 128, 0.8);
  padding: 15px;
  border-radius: 5px;
}

.reward-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
}

.reward-value {
  font-weight: normal;
}

.reward-value.blue {
  color: #00f7ff;
}

.share-friends-button {
  background: none;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
  margin-top: 20px;
  cursor: pointer;
  text-transform: uppercase;
}

.tasks-icon-container .icon {
  width: 50px;
  height: 50px;
}

.tasks-icon-container {
  position: absolute;
  top: 11%;
  right: 4%;
  display: flex;          /* or 'inline-flex' */
  flex-direction: column; /* stack them vertically, if desired */
  gap: 10px;              /* spacing between buttons */
}


/* ========== UPDATED FOR FULL-SCREEN LOADING VIDEO ========== */
.status-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Stacking on top of everything */
  z-index: 9999;
  /* We'll place the text absolutely near the bottom center. */
}

.loading-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; 
  z-index: 1; /* behind text */
}

.status-text {
  position: absolute;
  bottom: 40px; /* Adjust how high above the bottom you want it */
  left: 50%;
  transform: translateX(-50%);
  z-index: 2; /* above the video */
  color: red;
  font-size: 1.3rem;
  text-align: center;
  font-weight: bold;
}
/* =========================================================== */


/* ======== FINAL OVERRIDES FOR DYNAMIC SIZE & 55PX SIDE MARGINS ======== */
.clicker-container {
  /* Keep existing styles, only override the width to allow 55px side margins */
  width: calc(100% - 110px) !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.clicker-image {
  /* Make sure it never gets cut - use contain */
  object-fit: contain !important;
  /* Scale to container’s width */
  width: 100% !important;
  height: auto !important;
  pointer-events: none; /* container handles clicks, so the ripple works */
}

/* Ensure ripples appear above the image */
.ripple {
  z-index: 999 !important;
}

/* ======== NEW: Shift and "stretch" .tabs for Android only ======== */
.tabs-android {
  bottom: 60px !important;
}

.tabs-android::after {
  /* Fill the gap below the bar with the same color */
  content: "";
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #000000e7; /* same as .tabs background */
  z-index: 0; /* behind the bar (z-index:1) so it looks continuous */
}

/* [ADD] If vfx is off => override neon animations in .tabs, .tap-message, etc. */
.App.vfx-off .tabs {
  /* remove neonGlow, box-shadow, etc. */
  animation: none !important;
  box-shadow: none !important;
  border-top: 5px solid #555 !important;
}

.App.vfx-off .tap-message {
  /* remove pulse + box-shadow */
  animation: none !important;
  box-shadow: none !important;
  border: 5px solid #444 !important; /* reduce brightness */
}

.App.vfx-off .clicker-container {
  /* if you want to remove perspective? Otherwise keep it */
}

.App.vfx-off .balance-container {
  /* remove the glow? */
  box-shadow: none !important;
  text-shadow: none !important;
}

.App.vfx-off .Energy-descr {
  /* remove text shadow? */
  text-shadow: none !important;
}

.App.vfx-off .tabs button::before {
  /* remove radial highlight */
  background: none !important;
}