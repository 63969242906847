.bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    flex-wrap: wrap;
    overflow: hidden;
    perspective: 1000px;
    animation: sectionSwing 30s ease-in-out infinite alternate;
  }
  
  .bg::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(#000000a4, #00ffff, #000);
    opacity: 0.5;
    animation: animateBg 8s linear infinite;
    z-index: 1;
  }
  
  @keyframes animateBg {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(100%);
    }
  }
  
  @keyframes sectionSwing {
    0% {
      transform: rotateX(10deg) rotateY(10deg) translateY(-5%);
    }
    25% {
      transform: rotateX(-10deg) rotateY(5deg) translateY(5%);
    }
    50% {
      transform: rotateX(5deg) rotateY(-10deg) translateY(-5%);
    }
    75% {
      transform: rotateX(-5deg) rotateY(10deg) translateY(5%);
    }
    100% {
      transform: rotateX(10deg) rotateY(-10deg) translateY(-5%);
    }
  }
  
  .sp {
    position: relative;
    display: block;
    background: #181818;
    width: calc(16vw);
    height: calc(15vw);
    z-index: 2;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 255, 255, 0.3);
    transition: transform 0.5s ease;
  }
  