/* tutorial.css */
.tutorial-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Затемнённый фон */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.tutorial-container {
  background: #01dad295;
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  width: 70%;
  max-width: 400px;
  animation: slideUp 0.5s ease-out;
}

@keyframes slideUp {
  0% { transform: translateY(50px); }
  100% { transform: translateY(0); }
}

.tutorial-container h2 {
  font-size: 1.5rem;
  color: #01ff8d;
  font-weight: 600;
  margin-bottom: 10px;
}

.tutorial-container p {
  font-size: 1rem;
  color: #f3f3f3;
  line-height: 1.5;
  margin-bottom: 20px;
}

.tutorial-overlay button {
  padding: 10px 20px;
  background-color: #02fff7;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tutorial-box {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1); /* Легкий белый фон */
  border: 2px solid #06c6f1; /* Стильная синяя граница */
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Тень вокруг */
  padding: 10px;
  font-size: 1rem;
  color: #333;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.tutorial-box::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #5e7ce2;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.tutorial-button {
  width: 100%;
  height: 100%;
  position: absolute;
  visibility: hidden;
}

/* ------------------------------------------------------
   SWIPE ARROWS CONTAINER - APPEARS UNDER THE TUTORIAL BOX
------------------------------------------------------- */
.swipe-arrows-container {
  position: fixed;        /* so it stays in place under the tutorial */
  bottom: 100px;           /* adjust to place it lower on screen */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 40px;              /* space between arrows */
  z-index: 9998;          /* one less than tutorial overlay, so under if needed */
}

/* LEFT ARROW */
.swipe-arrow-left {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #ffffff; 
  animation: arrowLeftBounce 1s infinite alternate;
}
@keyframes arrowLeftBounce {
  0%   { transform: translateX(0); }
  100% { transform: translateX(-10px); }
}

/* RIGHT ARROW */
.swipe-arrow-right {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #ffffff; 
  animation: arrowRightBounce 1s infinite alternate;
}
@keyframes arrowRightBounce {
  0%   { transform: translateX(0); }
  100% { transform: translateX(10px); }
}
