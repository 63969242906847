.FarmBtn {
  font-family: monospace;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed; 
  bottom: 0%; 
  left: 50%; 
  transform: translateX(-50%); 
  font-weight: 500;
  font-size: 32px;
  color: rgb(0, 255, 255);
  height: 8%;
  width: 100%;
  padding: 2%;
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: 0px 0px 20px 5px rgba(0, 255, 234, 0.7);
  transition: all 0.5s ease-in-out;
  z-index: 1000; 
  border-radius: 10% 10% 0% 0;
  border: none;
}

.FarmBtn.ready-to-claim {
  color: rgb(196, 255, 2);
  text-shadow: 0 0 10px rgb(196, 255, 2);
  box-shadow: 0px 0px 20px 5px rgba(196, 255, 2, 0.7);
  animation: claimPulse 1.5s infinite alternate;
}

@keyframes claimPulse {
  from {
    box-shadow: 0px 0px 20px 5px rgba(196, 255, 2, 0.7);
  }
  to {
    box-shadow: 0px 0px 30px 10px rgba(196, 255, 2, 0.7);
  }
}

.FarmBtn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
