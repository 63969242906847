/* bg.css */
.bg1 {
  position: fixed;
  width: 100vw;
  height: 100dvh;
  height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex-wrap: wrap;
  overflow: hidden;
  border-radius: 5px;
  perspective: 1000px; /* Для 3D-эффекта */
}

.bg1::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(#000000a4, #00eeff, #000);
  opacity: 0.5;
  animation: animateBg 5s linear infinite;
  z-index: 1;
}

.sp1 {
  position: relative;
  display: block;
  background: #181818;
  width: calc(15vw);
  height: calc(15vw);
  z-index: 2;
  border-radius: 5%;
  box-shadow: 0 4px 5px rgba(0, 255, 255, 0.829);
  animation: flickerShadow 5s infinite, columnRotate 30s linear infinite;
}

/* OPTIONAL: If you want to remove flicker/rotation when VFX is off. */
.sp1.no-vfx {
  animation: none !important;
  box-shadow: none !important;
}

/* Animations, etc. */
@keyframes animateBg {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes flickerShadow {
  0%, 100% {
    box-shadow: 0 4px 5px rgba(3, 246, 246, 0.829);
  }
  25% {
    box-shadow: 0 6px 7px rgba(3, 246, 246, 0.6);
  }
  50% {
    box-shadow: 0 8px 10px rgba(3, 246, 246, 0.4);
  }
  75% {
    box-shadow: 0 6px 7px rgba(3, 246, 246, 0.6);
  }
}

@keyframes columnRotate {
  0% {
    transform: rotate3d(1, 1, 0, 0deg) scale(1);
  }
  100% {
    transform: rotate3d(1, 1, 0, 360deg) scale(1);
  }
}

@media (max-width: 768px) {
  .sp1 {
    width: calc(30vw);
    height: calc(30vw);
  }
}
