.tasks {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(135deg, #1e1e2f, #2a2a3d); /* Градиентный фон */
  padding: 20px;
  color: #ffffff;
  font-family: 'Inter', sans-serif; /* Современный шрифт */
}

.tasks-header {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  background: linear-gradient(90deg, #6c63ff, #60d394);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 30px;
}

.tasks-list {margin-top: 20%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 900px;
  overflow-y: auto;
  padding: 10px;
  background: rgba(255, 255, 255, 0.05); /* Полупрозрачный фон */
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}
.tasks-list::-webkit-scrollbar {
  width: 8px;
}

.tasks-list::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #6c63ff, #60d394);
  border-radius: 10px;
}

.task-card {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  background: linear-gradient(135deg, #252531, #313140);
  border-radius: 15px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.task-card.completed {
  background: #3b3b4e;
  opacity: 0.7;
  pointer-events: none;
}

.task-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  object-fit: cover;
}

.task-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.task-name {
  font-size: 1.3rem;
  font-weight: 600;
  color: #ffffff;
}

.task-reward {
  font-size: 1rem;
  font-weight: 500;
  color: #60d394;
}


.tasks-icon-container {z-index: 4;
  position: absolute;
  top: 20%;
  right: 5%;
  width: 40px;
  height: 40px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  /* background-color: #e9e9e9; */
  border-radius: 50%;
  /* border: 1px solid #cccccc; */
}

.tasks-icon-container button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
.tasks-list button {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
  background: linear-gradient(90deg, #6c63ff, #60d394);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}



.tasks-list button:disabled {
  background: #555;
  cursor: not-allowed;
  
}
.tasks {
  position: relative;
}

.task-tabs {
  position: absolute;
  top: 8%; /* Центрируем по вертикали */
  left: 50%; /* Центрируем по горизонтали */
  transform: translate(-50%, -50%); /* Корректируем смещение на 50% от ширины и высоты */
  display: flex;
  gap: 10px;
}


.tab-button {
  background: linear-gradient(90deg, #6c63ff, #60d394);
  color: #fff;
  border: none;
  padding: 5px 10px;
  margin: 5px 0;
  cursor: pointer;
  font-size: 16px;
  border-radius: 8px;
  transition: all 0.3s ease; /* Плавные анимации */
}

.tab-button:hover {
  background-color: #444;
}

.tab-button.active {
  background: linear-gradient(45deg, #00e1ff, #007bff);
  color: #fcf9f9;
  border-radius: 50px; /* Углы становятся круглыми */
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}


.tab-button.active:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px;
  background-color: #fcf9f9;
  transform: translate(-50%, -50%);
  animation: underline-animation 0.5s ease forwards;
}

/* Анимация подчеркивания */
/*
@keyframes underline-animation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
*/