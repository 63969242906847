/* UniverseSwitcher.css */

/* Remove margins, set full screen */
html, body {
  overscroll-behavior: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.container {
  z-index: 3;
  width: 100%;
  height: 100vh;
  position: relative;
  touch-action: pan-x;
}

/* The "loading-screen" overlay */
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  width: 100vw;
  height: 100vh;

  /* Change background to black (or #000) to avoid red flicker */
  background-color: #000;

  z-index: 1000;
}

/* Fallback div (shown until video is loaded) */
.video-fallback {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000; /* same color as .loading-screen */
  z-index: 1;
}

/* The actual <video> that covers the screen once loaded */
.loading-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;

  /* pointer-events: none ensures iOS taps do not pause */
  pointer-events: none;
}

/* The text pinned at the bottom */
.loading-screen p {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 24px;
  margin: 0;
  white-space: nowrap;
  z-index: 3; /* above the video */
}

/* Animate the trailing dots (...) after "Changing to X" */
.loading-screen p::after {
  content: '';
  display: inline-block;
  animation: ellipsis 1.5s infinite;
}

@keyframes ellipsis {
  0% { content: ''; }
  33% { content: '.'; }
  66% { content: '..'; }
  100% { content: '...'; }
}
