.referral-page {
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  color: white;
  position: relative;
  overflow-y: auto;
}

.referral-header {
  background-color: #010000e7;
  border-bottom: 5px solid rgb(0, 174, 255);
  padding: 15px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 0 5px rgb(0, 174, 255), 0 0 10px rgb(0, 174, 255), 0 0 15px rgb(0, 174, 255);
  animation: neonGlow 1.5s infinite alternate;
}

.referral-header h1 {
  margin: 0 auto;
  font-size: 24px;
  color: #00f7ff;
  text-shadow: 0 0 5px #00ffff, 0 0 10px #00ffff, 0 0 15px #00ffff;
}
.referral-content{
  margin-top: 15%;
}
.referral-header .back-button {
  background: none;
  border: none;
  color: #00f7ff;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  text-shadow: 0 0 5px #00ffff, 0 0 10px #00ffff;
}

.referral-content {
  padding: 20px;
}

.link-section {
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid rgb(0, 195, 255);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgb(0, 195, 255), 0 0 10px rgb(0, 195, 255);
}

.link-section h2 {
  color: #00f7ff;
  margin-bottom: 15px;
  text-shadow: 0 0 5px #00ffff;
}

.link-container {
  display: flex;
  gap: 10px;
}

.link-container input {
  flex: 1;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid rgb(0, 195, 255);
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-size: 14px;
}

.link-container button {
  background-color: rgb(0, 195, 255);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  color: black;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 0 5px rgb(0, 195, 255);
}

.rewards-section {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.reward-box {
  flex: 1;
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid rgb(0, 195, 255);
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 0 5px rgb(0, 195, 255);
}

.reward-box h3 {
  color: #00f7ff;
  margin-bottom: 10px;
  text-align: center;
  text-shadow: 0 0 5px #00ffff;
}

.reward-box p {
  color: rgb(196, 255, 2);
  text-align: center;
  margin: 5px 0;
  text-shadow: 0 0 5px rgb(196, 255, 2);
}

.referrals-section {
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid rgb(0, 195, 255);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgb(0, 195, 255);
}

.referrals-section h2 {
  color: #00f7ff;
  margin-bottom: 15px;
  text-shadow: 0 0 5px #00ffff;
}

.referrals-list {
  max-height: 200px;
  overflow-y: auto;
}

.referral-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 195, 255, 0.3);
}

.referral-username {
  color: #00f7ff;
  text-shadow: 0 0 3px #00ffff;
}

.referral-date {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
}

.share-button {
  width: 90%;
  background-color: rgb(0, 195, 255);
  border: none;
  padding: 15px;
  border-radius: 10px;
  color: black;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 2%;
  box-shadow: 0 0 5px rgb(0, 195, 255), 0 0 10px rgb(0, 195, 255);
  animation: neonGlow 1.5s infinite alternate;
  position: fixed; /* Это фиксирует кнопку */
  bottom: 0; /* Размещает кнопку внизу */
  left: 50%; /* Размещает кнопку по горизонтали в центре */
  transform: translateX(-50%); /* Смещает кнопку на половину её ширины */
  z-index: 1000; /* Убедитесь, что кнопка находится поверх других элементов */
}

@keyframes neonGlow {
  0% {
    box-shadow: 0 0 5px rgb(0, 174, 255), 0 0 10px rgb(0, 174, 255), 0 0 15px rgb(0, 174, 255);
  }
  100% {
    box-shadow: 0 0 10px rgb(0, 174, 255), 0 0 20px rgb(0, 174, 255), 0 0 30px rgb(0, 174, 255);
  }
}

/* Стилизация скроллбара */
.referrals-list::-webkit-scrollbar {
  width: 5px;
}

.referrals-list::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.3);
}

.referrals-list::-webkit-scrollbar-thumb {
  background: rgb(0, 195, 255);
  border-radius: 5px;
}
