.apple-catcher {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.game-area {
    width: 100vw;
    height: 100vh;
    position: relative;
    bottom: 0;
}

.player {
    position: absolute;
    bottom: 15%;
    transform: translateX(-10%);
}

.game-info {
    position: absolute;
    top: 40px; /* was 10px, now 40px to move ~30px down */
    left: 10px;
    display: flex;
    justify-content: space-between;
    width: 200px;
    font-size: 18px;
    color: rgb(134, 237, 8);
    background-color: rgba(4, 255, 255, 0.204);
    padding: 10px;
    border-radius: 5px;
    z-index: 10;
}

/* Toggle VFX button at top-right */
.vfx-toggle-button {
    position: absolute;
    top: 40px; /* was 10px, now 40px to move ~30px down */
    right: 10px;
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    z-index: 10;
    opacity: 0.8;
}
.vfx-toggle-button:hover {
    opacity: 1;
}

.start-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px 30px;
    font-size: 24px;
    cursor: pointer;
    background-color: #08ece59a;
    color: white;
    border: none;
    border-radius: 5px;
    z-index: 10;
}

.result-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.result-content {
    background-color: rgb(1, 255, 225);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

.result-content h2 {
    margin-top: 0;
}

.result-content button {
    margin-top: 10px;
    padding: 5px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #a1bd26;
    color: rgb(15, 244, 248);
    border: none;
    border-radius: 5px;
}

.score-animation {
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    opacity: 1;
    transform: translateY(0) scale(1);
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
    pointer-events: none;
}

.score-animation.positive {
    color: rgb(0, 209, 233);
}

.score-animation.negative {
    color: red;
}

.score-animation.active {
    opacity: 0;
    transform: translateY(-30px) scale(1.5);
}

/* ----- VFX ON (Neon) ----- */
.neon-apple {
    position: absolute;
    background-color: green; /* default for good-apple, overridden if .rotten-apple is used */
    filter: drop-shadow(0 0 10px #00ff00) drop-shadow(0 0 20px #1e361e);
    animation: neon-green-glow 1.5s infinite alternate;
}
.neon-apple.rotten-apple {
    background-color: brown;
    filter: drop-shadow(0 0 10px #ff0000) drop-shadow(0 0 20px #ff0000);
    animation: neon-red-glow 1.5s infinite alternate;
}

.neon-catcher {
    filter: drop-shadow(0 0 10px #00aaff) drop-shadow(0 0 20px #00aaff);
    animation: neon-blue-glow 1.5s infinite alternate;
    transform: translateX(-50%);
}

/* ----- VFX OFF (Plain) ----- */
.plain-apple {
    position: absolute;
}
.plain-apple.good-apple {
    background-color: green;
}
.plain-apple.rotten-apple {
    background-color: brown;
}
.plain-catcher {
    transform: translateX(-50%);
    /* no neon glow; actual color is assigned inline as "grey" */
}

/* Neon animations */
@keyframes neon-green-glow {
    from {
        filter: drop-shadow(0 0 5px #00ff00) drop-shadow(0 0 10px #00ff00);
    }
    to {
        filter: drop-shadow(0 0 20px #00ff00) drop-shadow(0 0 40px #1e361e);
    }
}

@keyframes neon-red-glow {
    from {
        filter: drop-shadow(0 0 5px #ff0000) drop-shadow(0 0 10px #ff0000);
    }
    to {
        filter: drop-shadow(0 0 20px #ff0000) drop-shadow(0 0 40px #800000);
    }
}

@keyframes neon-blue-glow {
    from {
        filter: drop-shadow(0 0 5px #00aaff) drop-shadow(0 0 10px #00aaff);
    }
    to {
        filter: drop-shadow(0 0 20px #00aaff) drop-shadow(0 0 40px #001122);
    }
}
