/* WalletPage.css */
/* Hide the "View all wallets" button in the TonConnect wallet modal */
.tonconnect-modal__view-all, 
.view-all-wallets {
  display: none !important;
}


/* Overall page container with black background and white text */
.wallet-page {
  width: 100%;
  min-height: 100vh;
  background-color: rgb(0, 0, 0);
  color: white;
  position: relative;
  overflow-y: auto;
  font-family: sans-serif;
  /* Push content lower on the screen */
  padding-top: 50px;
}

/* Main content wrapper */
.wallet-content {
  margin-top: 2%;
  padding: 20px;
}

/* Title container for centering the heading */
.wallet-title-container {
  display: flex;
  justify-content: center; /* centers horizontally */
  margin-bottom: 20px;
}

.wallet-title {
  color: #00f7ff;
  text-shadow: 0 0 5px #00ffff;
  font-size: 24px;
  margin: 0; /* remove default <h1> margin */
  text-align: center;
}

/* Neon glow animation (optional) */
@keyframes neonGlow {
  0% {
    box-shadow: 0 0 5px rgb(0, 174, 255), 0 0 10px rgb(0, 174, 255),
      0 0 15px rgb(0, 174, 255);
  }
  100% {
    box-shadow: 0 0 10px rgb(0, 174, 255), 0 0 20px rgb(0, 174, 255),
      0 0 30px rgb(0, 174, 255);
  }
}

/* Hide the default TonConnect button (if the library tries to inject it) */
.ton-connect-button__button {
  display: none !important;
}

/* Donation section wrapper (similar to referral link-section) */
.link-section {
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid rgb(0, 195, 255);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgb(0, 195, 255), 0 0 10px rgb(0, 195, 255);
}

.link-section h2 {
  color: #00f7ff;
  margin-bottom: 10px;
  text-shadow: 0 0 5px #00ffff;
}

.total-donated {
  color: rgb(196, 255, 2);
  margin-bottom: 15px;
  text-shadow: 0 0 5px rgb(196, 255, 2);
}

/* Donation input/button container */
.donation-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.donation-input {
  flex: 1;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid rgb(0, 195, 255);
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  text-align: center;
}

.donate-button {
  background-color: rgb(0, 195, 255);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  color: black;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 0 5px rgb(0, 195, 255);
}

/* Transactions list */
.transactions-section {
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid rgb(0, 195, 255);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgb(0, 195, 255);
}

.transactions-section h2 {
  color: #00f7ff;
  margin-bottom: 10px;
  text-shadow: 0 0 5px #00ffff;
}

.transactions-list {
  max-height: 200px;
  overflow-y: auto;
}

.transaction-item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 195, 255, 0.3);
  padding: 10px 0;
}

.transaction-amount {
  color: #00f7ff;
  text-shadow: 0 0 3px #00ffff;
}

.transaction-date {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
}

/* Modal content (MUI Modal child) */
.modal-content {
  background-color: #ffffff;
  color: #000000;
  padding: 1.5rem;
  margin: 40% auto;
  max-width: 300px;
  border-radius: 8px;
}

/* Custom scrollbar for the transactions list */
.transactions-list::-webkit-scrollbar {
  width: 5px;
}
.transactions-list::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.3);
}
.transactions-list::-webkit-scrollbar-thumb {
  background: rgb(0, 195, 255);
  border-radius: 5px;
}

/* Single container for connect or disconnect button at bottom (fixed position) */
.wallet-fixed-button {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  animation: neonGlow 1.5s infinite alternate;
}

/* Custom connect button with no border */
.connect-button {
  background: transparent;
  color: #00f7ff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: none;
  text-transform: uppercase;
  font-weight: bold;
}

/* On hover, add a subtle background highlight */
.connect-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* Disconnect button with red background, no border */
.disconnect {
  background-color: rgb(255, 0, 0);
  border: none;
  padding: 14px 22px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 0 5px rgb(255, 0, 0);
}

/* Style for the scam alert text */
.scam-alert {
  font-size: 0.7rem;
  color: #ff5555;
  font-weight: bold;
  margin: 0 0.5rem;
}

/* (Optional) style short-address if needed */
.short-address {
  font-size: 0.8rem;
  color: #00f7ff;
}
