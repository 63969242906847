* {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
  }
  
  html, body {
    height: 100%;
  }
  
  body {
    display: table;
    width: 100%;
    height: 100%;
    background-color: #171717;
    color: #000;
    line-height: 1.6;
    position: relative;
    font-family: sans-serif;
    overflow: hidden;
  }
  .contain{
    height: 100vh;
  display: flex;
  justify-content: center;
  align-items: end;
}
.content3{
    border-radius: 30% 30% 0 0;
    font-weight: bolder;
    background-color: rgb(6, 120, 135);
    text-decoration: dotted;
    text-align: center;
    width: 100%;
    height: 8%;
    font-size: 220%;
    box-shadow: 
    0 0 50px 30px rgba(0, 225, 255, 0.8),  
    0 0 100px 50px rgba(0, 255, 195, 0.6);  
  }
  .lines {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 90vw;
  }
  
  .line {
    position: absolute;
    border: 1px solid rgb(6, 206, 213);
    width: 10px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
    box-shadow: 
    0 0 20px 10px rgba(0, 225, 255, 0.8), 
    0 0 70px 10px rgba(0, 255, 195, 0.6);  
    
  }
  
  .line::after {
    content: '';
    display: block;
    position: absolute;
    height: 15vh;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
    animation: drop 7s 0s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
  }
  
  
  
  .line:nth-child(1) {
    margin-left: -25%;
  }
  
  .line:nth-child(1)::after {
    animation-delay: 2s;
  }
  
  .line:nth-child(3) {
    margin-left: 25%;
  }
  
  .line:nth-child(3)::after {
    animation-delay: 2.5s;
  }
  
  @keyframes drop {
    0% {
      top: -50%;
    }
    100% {
      top: 120%;
    }
  }
  .neon-glow-r {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: #00e5ff;
    border-radius: 50%;
    box-shadow: 
    0 0 50px 30px rgba(0, 225, 255, 0.8),  
    0 0 70px 50px rgba(0, 255, 195, 0.6);  
    animation: glow-up 9s infinite ease-in-out;
}
.neon-glow-r1 {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: #34dc01;
  border-radius: 50%;
  box-shadow: 
    0 0 50px 30px rgba(4, 255, 0, 0.8),  
    0 0 70px 50px rgba(0, 234, 255, 0.6);  
  animation: glow-up 7s infinite ease-in-out;
}
  .neon-glow-l1 {
    position: absolute;
    top: 50%;
    left: 4%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: #00d0ff;
    border-radius: 50%;
    box-shadow: 
    0 0 50px 30px rgba(0, 225, 255, 0.8), 
    0 0 70px 50px rgba(0, 255, 81, 0.6); 
    animation: glow-up 7s infinite ease-in-out;
}
.neon-glow-l {
  position: absolute;
  top: 50%;
  left: 4%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: #0dff00e0;
  border-radius: 50%;
  box-shadow: 
  0 0 50px 30px rgba(43, 255, 0, 0.8),  
  0 0 70px 50px rgba(0, 174, 255, 0.6); 
  animation: glow-up 9s infinite ease-in-out;
}


@keyframes glow-up {
    0% {
        top: 100%;
        opacity: 0;
        transform: translateX(-50%) translateY(0);
    }
    50% {
        top: 50%;
        opacity: 1;
    }
    100% {
        top: 0;
        opacity: 0;
        transform: translateX(-50%) translateY(-100%);
    }
}