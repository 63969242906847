.circular-progress-bar {
    top: 20%;
    position: relative;
    width: 250px; /* Размер компонента */
    height: 250px;
    border-radius: 50%; /* Округление для центра */
    box-shadow: 0 0 55px 12px #00ffff; /* Неоновое свечение бордера */
    overflow: hidden; 
  }
  
  .circular-progress-bar::before {
    content: '';
    position: absolute;
    top: 5px; 
    left: 5px;
    width: calc(100% - 10px); 
    height: calc(100% - 10px);
    border-radius: 50%; 
    background: radial-gradient(circle, rgba(0, 255, 255, 0) 0%, rgba(0, 255, 255, 0.1) 70%, transparent 100%);
    box-shadow: inset 0 0 15px 5px rgba(0, 255, 255, 0.3); /* Внутреннее свечение */
    animation: pulse 3.5s infinite; /* Пульсация */
  }
  
  .progress-svg {
    transform: rotate(-90deg); /* Поворот для правильного отображения прогресса */
    width: 100%;
    height: 100%;
    border-radius: 50%; /* Округление для самого SVG */
    box-shadow: inset 0 0 15px 5px rgba(0, 255, 255, 0.3); /* Внутреннее свечение для SVG */
   
  }
  
  .progress-block {
    fill: #000000; /* Цвет незаполненных блоков */
    transition: fill 0.3s ease, transform 0.3s ease;
  }
  
  .progress-block.filled {
    fill: #00ffff; /* Неоновый цвет */
    filter: drop-shadow(0 0 6px #00ffff); /* Неоновое свечение */
    
  }
  
  .progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5em;
    font-weight: bold;
    color: #00ffff;
    text-shadow: 0 0 8px #00ffff; /* Неоновое свечение текста */
    font-family: monospace; /* Моноширинный шрифт */
     /* animation: pulse 1.5s infinite;  */
   
  }
  
  /* Анимация пульсации */
  @keyframes pulse {
    0% {
      box-shadow: 0 0 15px 5px rgba(0, 255, 255, 0.3);
    }
    100% {
      box-shadow: 0 0 25px 10px rgba(0, 255, 255, 0.5);
    }
  }
  
  /* Анимация свечения блока */
  @keyframes glow {
    0% {
      filter: drop-shadow(0 0 6px #00ffff);
    }
    100% {
      filter: drop-shadow(0 0 15px #00ffff);
    }
  }
  
  /* Анимация свечения текста */
  @keyframes text-glow {
    0% {
      text-shadow: 0 0 8px #00ffff;
    }
    100% {
      text-shadow: 0 0 16px #00ffff;
    }
  }
  