.upgrades {
  position: absolute;
  z-index: 3;
  display: grid;
  grid-template-rows: auto auto auto; /* Каждая строка под свои элементы */
  gap: 20px;
  padding: 5% 5%; /* Уменьшено внутреннее отступы */
  background-color: #1c1c1c; /* Тёмный фон для контраста */
  color: white;
  height: 100vh;
  width: 100vw;
  overflow-y: auto; /* Добавляем прокрутку, чтобы контент не выходил за пределы */
}

.upgrade-section, .autofarm-section, .boost-section {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  background: rgba(22, 21, 21, 0.9); /* Темный полупрозрачный фон */
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 5px 20px rgba(0, 255, 255, 0.3); /* Глубокая тень */
  width: 100%; /* Подстраиваем ширину под экран */
}

/* Заголовок секции */
.header_upg {
  color: #7b7b7b;
  font-size: 24px;
  font-weight: 800;
  font-family: 'Courier New', monospace;
  margin-bottom: 5px;
}

/* Кнопки */
.upgrades button {
  
  width: 95%;
  max-width: 100%; /* Расширяем кнопку на всю ширину */
  background: linear-gradient(145deg, rgba(2, 2, 2, 0.9), rgb(0, 200, 255));
  color: white;
  border: none;
  padding: 15px 20px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: transform 0.2s, box-shadow 0.3s, background 0.3s;
}



.upgrades button:active {
  
  transform: scale(0.95);
  background: rgba(0, 255, 200, 0.9);
}

/* Отключенная кнопка */
.upgrades button:disabled {
  cursor: not-allowed;
  color: rgba(255, 255, 255, 0.5);
  background: linear-gradient(145deg, rgb(6, 6, 6), rgba(234, 255, 4, 0.9));
  box-shadow: none;
}

/* Плейсхолдер */
.upgrade-placeholder {
  
  width: 95%;
  max-width: 100%;
  background: linear-gradient(145deg, rgb(6, 6, 6), rgba(234, 255, 4, 0.9));

  border: none;
  padding: 15px 20px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: bold;
}
.upgrades h4{
  margin-bottom: 10px;
}
/* Текст внутри секций */
/* Текст внутри секций */
.upgrade-txt {
  font-family: 'Courier New', monospace;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px; /* Увеличен отступ для улучшенной читаемости */
  color: white;
}

/* Параграфы */
.upgrades p {
  font-family: 'Courier New', monospace;
  font-size: 14px;
  color: rgb(255, 255, 255);
  margin-bottom: 10px; /* Добавлен отступ для разделения параграфов */
}

/* Подсказки */
.tooltip {
  font-family: 'Courier New', monospace;
  display: block;
  margin-top: 10px; /* Увеличен отступ для отделения подсказки от текста */
  font-size: 14px;
  color: #000000;
}


/* Стиль для недоступных кнопок */
.disabled-btn {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  border: 1px solid #999;
  box-shadow: none;
}

/* Адаптивные стили */
@media (max-width: 768px) {
  .upgrades {
    padding: 10% 2%; /* Уменьшаем отступы */
  }

  .upgrades button {
    font-size: 14px; /* Уменьшаем размер текста на маленьких экранах */
    padding: 10px 15px; /* Уменьшаем отступы кнопок */
  }

  .upgrade-section, .autofarm-section, .boost-section {
    padding: 15px; /* Уменьшаем отступы внутри секций */
  }
}

/* Auto-Farm Button Styles */
.auto-farm-btn {
  width: 95%;
  max-width: 100%;
  background: linear-gradient(145deg, rgba(2, 2, 2, 0.9), rgb(0, 200, 255));
  color: white;
  border: none;
  padding: 15px 20px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: transform 0.2s, box-shadow 0.3s, background 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
}

.auto-farm-btn.active {
  background: linear-gradient(145deg, rgba(0, 100, 0, 0.9), rgb(0, 255, 100));
  box-shadow: 0 5px 20px rgba(0, 255, 0, 0.3);
}

.auto-farm-btn.inactive {
  background: linear-gradient(145deg, rgba(2, 2, 2, 0.9), rgb(0, 200, 255));
}

.auto-farm-btn:active {
  transform: scale(0.95);
}

/* If you haven't already set this tooltip style: */
.tooltip {
  font-family: 'Courier New', monospace;
  display: block;
  margin-top: 10px;
  font-size: 14px;
  color: #000000;
}

.confirm-dialog-overlay {
  position: fixed; /* fixed position so it stays in place on scroll */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* a semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* ensure it appears on top of all other content */
}

.confirm-dialog {
  background: #1c1c1c;
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 90%;
}

.confirm-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.confirm-buttons button {
  background: linear-gradient(145deg, rgba(2, 2, 2, 0.9), rgb(0, 200, 255));
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: transform 0.2s, box-shadow 0.3s, background 0.3s;
}

