/* GiftTab.css */

.gift-tab-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #242424;
  min-height: 100vh;
  color: #fff;
  position: relative; /* for the back button */
}

.gift-title {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}

.donut-box-wrapper {
  margin-bottom: 20px;
}
.donut-box {
  width: 150px;
  height: 150px;
}

/* Roller window is a fixed 400px wide, 100px tall. */
.csgo-roller-window {
  position: relative;
  width: 400px;
  height: 100px;
  overflow: hidden; /* hides the extra spool items */
  border: 2px solid #fff;
  border-radius: 8px;
  margin-bottom: 20px;
}

.csgo-roller {
  display: flex;
  align-items: center;
  height: 100%;
  /* no width limit so it can contain 100 items horizontally */
}

/* Each prize block is 80px wide, 80px tall. */
.csgo-prize {
  width: 80px;
  height: 80px;
  margin: 0 5px;
  background: #333;
  border: 2px solid #666;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

/* Red highlight line in the center */
.csgo-roller-highlight {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 100%;
  background: red;
}

.spin-btn {
  padding: 12px 20px;
  background: #01ff8d;
  color: #000;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 10px;
}
.spin-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Golden controls => multiplier buttons */
.golden-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.multiplier-choice {
  display: flex;
  gap: 8px;
  margin-top: 10px;
}
.multiplier-choice button {
  padding: 8px 16px;
  background: gold;
  color: #000;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}
.multiplier-choice .active {
  border: 2px solid #fff;
}

/* Final result text */
.prize-result {
  margin-top: 20px;
  text-align: center;
}
