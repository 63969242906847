/* EWE.css */

/* Original styles */
html, body {
  overflow: hidden;
}

* {
  padding: 0;
  margin: 0;
  font-style: none;
  list-style: none;
  font-family: Arial, sans-serif;
}

.App1 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: black;
}

.header {
  background-color: black;
  height: 12%;
  width: 100vw;
  display: flex;
  align-items: flex-start;
  padding-top: 55px;
  justify-content: center;
  border-radius: 0 0 30px 30px;
  box-shadow: 0px 0px 20px 5px rgba(0, 255, 234, 0.7);
  border-bottom: 10px solid rgba(0, 255, 234, 0.9);
  z-index: 1;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  flex: 1;
  padding: 1rem;
  align-items: center; 
}

/* =================== ADDED FOR FULL-SCREEN LOADING & TEXT =================== */
.status-overlay {
  position: fixed;
  top: 0; 
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999; /* On top of everything */
}

.loading-video {
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1; /* behind the text */
}

.status-text {
  position: absolute;
  bottom: 40px; /* how far from bottom you want the text */
  left: 50%;
  transform: translateX(-50%);
  z-index: 2; /* above video */
  color: red;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
}
/* =========================================================================== */
