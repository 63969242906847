.boost {
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.818);
  position: absolute;
  height: 100vh;
  padding: 13% 3% 0;
  align-items: center;
  display: flex;
  width: 100vw;
  flex-direction: column;
}

.game-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px 10px;
  overflow-y: auto;
  max-height: 100vh;
}

/* Custom scrollbar styles for Webkit */
.game-cards::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.game-cards::-webkit-scrollbar-thumb {
  background-color: rgb(0, 204, 255);
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.game-cards::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

/* Firefox scrollbar styling */
.game-cards {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 225, 255, 0.7) rgba(0, 0, 0, 0.2);
}

.game-card {
  position: relative;
  background-color: #333;
  border: 2px solid rgba(0, 187, 255, 0.315);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 120, 255, 0.5);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: 100px;
}

.game-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 120, 255, 0.7);
}

.game-card-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.game-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.game-title {
  font-family: 'Monospace', sans-serif;
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 24px;
  text-shadow: 
    0 2px 3px rgba(0, 0, 0, 0.4),
    0 4px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
  pointer-events: none;
  line-height: 1.3;
}

.game-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5%;
  width: 100%;
  background-color: rgb(43, 42, 42);
  height: 100vh;
  border-top: 2px solid rgba(0, 187, 255, 0.315);
  border-radius: 2%;
  border-left: 2px solid rgba(0, 187, 255, 0.315);
  border-right: 2px solid rgba(0, 187, 255, 0.315);
  box-shadow: 0 5px 15px rgba(0, 120, 255, 0.5);
}

.hov {
  color: #00ffffe8;
  font-size: 24px;
  font-family: monospace;
  font-weight: 700;
}

.game-container {
  width: 100%;
  position: absolute;
  height: 100%;
}

.gameBTN {
  box-shadow: 0 6px 11px rgba(1, 1, 1, 0.7);
  width: 80%;
  color: rgb(255, 225, 53);
  background-color: rgba(0, 234, 255, 0.6);
  font-size: 130%;
  margin-top: 2%;
  padding: 10px;
  border: 2px solid rgba(255, 242, 3, 0.4);
}

/* Notification styles */
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: rgba(43, 42, 42, 0.95);
  color: #00ffffe8;
  padding: 15px 25px;
  border-radius: 8px;
  z-index: 1000;
  animation: slideInFromRight 0.3s ease-in-out;
  border: 2px solid rgba(0, 187, 255, 0.315);
  box-shadow: 0 5px 15px rgba(0, 120, 255, 0.5);
  font-family: monospace;
  font-weight: 500;
  font-size: 16px;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  max-width: 300px;
  word-wrap: break-word;
}

@keyframes slideInFromRight {
  from {
    opacity: 0;
    transform: translateX(100px);
    box-shadow: 0 0 0 rgba(0, 120, 255, 0);
  }
  to {
    opacity: 1;
    transform: translateX(0);
    box-shadow: 0 5px 15px rgba(0, 120, 255, 0.5);
  }
}

/* Styling for the attempts counter (only for BOMBS) */
.attempts-counter {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #00ffffe8;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-family: monospace;
  border: 1px solid rgba(0, 187, 255, 0.315);
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
  z-index: 2;
}

/* Styling for the referrals counter (only for Purble Pairs) */
.referrals-counter {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #00ffffe8;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-family: monospace;
  border: 1px solid rgba(0, 187, 255, 0.315);
  text-shadow: 0 0 5px rgba(0, 255, 255, 0.5);
  z-index: 2;
}

/* Styles for "Coming Soon" content */
.soon-content {
  background-color: rgba(43, 42, 42, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.soon-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-bottom: 5px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .notification {
    top: 70px;
    right: 10px;
    left: auto;
    max-width: calc(100% - 80px);
    font-size: 14px;
    padding: 12px 20px;
  }

  .attempts-counter, .referrals-counter {
    font-size: 10px;
    padding: 2px 6px;
  }

  .game-card {
    height: 80px;
  }

  .game-title {
    font-size: 18px;
  }
}
