/* 
  Notification Icon Styles
  (unchanged from your existing code; 
  pinned to top-left in the main app)
*/
.notification-icon-button {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50% 0% 50% 40%;
  background: rgba(0, 0, 0, 0.671);
  border: none;
  cursor: pointer;
  position: absolute;
  top: 20%;
  left: 4%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px #00fbff, 0 0 20px #aa00ff;
}

.notification-icon {
  object-fit: cover;
  width: 48px;
  height: 48px;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background: #ff00ff;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ----------------------------
   Notifications “Page” container
   (covers entire screen, black background)
----------------------------- */
.notifications-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgb(0, 0, 0);
  color: white;
  z-index: 1000;
  width: 100vw;
  max-height: 100%;
  overflow-y: scroll; /* or 'auto' as you prefer */
  -webkit-overflow-scrolling: touch;
  /* no padding-top here so background starts from very top */
}

/* 
   Main content wrapper, 
   akin to .wallet-content 
*/
.notifications-content {
  margin-top: 50px; /* pushes everything down, similar to .wallet-page padding-top */
  padding: 20px;
  /* you can adjust margin-top or padding to match your wallet exactly */
}

/* 
  Title container & title, 
  matching .wallet-title-container & .wallet-title 
*/
.notifications-title-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.notifications-title {
  color: #00f7ff;
  text-shadow: 0 0 5px #00ffff;
  font-size: 24px;
  margin: 0;
  text-align: center;
}

/* 
   A “section” block 
   similar to .link-section or .transactions-section 
*/
.notifications-section {
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid rgb(0, 195, 255);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgb(0, 195, 255), 0 0 10px rgb(0, 195, 255);
}

/* 
   The scroller for the notifications 
*/
.notifications-list {
  max-height: 300px; /* adjust as needed */
  overflow-y: auto;
  padding-right: 5px; /* some room for the scrollbar */
}

/* Custom scrollbar (optional) */
.notifications-fullscreen::-webkit-scrollbar {
  width: 8px;
}
.notifications-fullscreen::-webkit-scrollbar-thumb {
  background-color: rgba(0, 195, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 195, 255, 0.8);
}
.notifications-fullscreen::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.notifications-fullscreen::-webkit-scrollbar:hover {
  width: 10px;
}
/* Firefox scrollbar */
.notifications-fullscreen {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 195, 255, 0.8) rgba(0, 0, 0, 0.1);
}

/* 
   Single notification item 
*/
.notification-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 195, 255, 0.3);
  padding: 10px 0;
}

.notification-icon-emoji {
  font-size: 24px;
  margin-right: 12px;
}

.notification-details {
  color: white;
  flex: 1;
}

.notification-message {
  color: white;
  margin-bottom: 4px;
}

.notification-bonus {
  color: rgb(188, 255, 2);
  font-weight: bold;
}

/* 
   Claim button 
   (kept from your original code)
*/
.claim-button {
  padding: 8px 16px;
  background: #04ffee7e;
  color: rgb(196, 255, 2);
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  box-shadow: 0 0 10px #00ffff, 0 0 20px #ff00ff;
  animation: glow 1.8s infinite alternate;
}

@keyframes glow {
  0% { 
    color: rgba(196, 255, 2, 1);
    text-shadow: 
      0 0 5px #00ffff,
      0 0 10px #00ffff, 
      0 0 15px #00ffff;
    box-shadow: 
      0 0 10px #00ffff,
      0 0 20px #0084ff, 
      0 0 30px #ff00ff;
  }
  100% { 
    color: #fff;
    text-shadow: 
      0 0 10px #00ffff, 
      0 0 20px #00ffff;
    box-shadow: 
      0 0 15px #00ffff, 
      0 0 15px #ff00ff;
  }
}

/* Neon glow animation (optional) */
@keyframes neonGlow {
  0% {
    box-shadow: 0 0 5px rgb(0, 174, 255),
                0 0 10px rgb(0, 174, 255),
                0 0 15px rgb(0, 174, 255);
  }
  100% {
    box-shadow: 0 0 10px rgb(0, 174, 255),
                0 0 20px rgb(0, 174, 255),
                0 0 30px rgb(0, 174, 255);
  }
}
