/* ProgressBar.css */
.progress-bar {position: absolute;
    z-index: -1;
    display: flex;
    width: 100vw;
    height: 100%; 
    background-color: #00ffff; /* Цвет фона полосы */
    border-radius:0 0 5px 5px;
    overflow: hidden;
      animation: neonGlow 1.5s infinite alternate;
  }
  
  .progress-slot {
    flex: 1;
    background-color: #ffffff; /* Цвет незаполненного слота */
    transition: background-color 0.3s ease-in-out;
  }
  
  .progress-slot.filled {
    background-color: #00baf8; /* Цвет заполненного слота */
  }
  